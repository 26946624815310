import React from "react";
import { Link } from "gatsby";
import { DrPlanasLogo, QuoteIcon } from "components/Graphics";
import { InstagramIcon, YoutubeIcon, TwitterIcon, FacebookIcon } from "components/Graphics";

const navigation = {
  company: [
    { name: "Blog", href: "/blog" },
    { name: "Preguntas Frequentes", href: "#" },
    { name: "Contacto", href: "#" },
  ],
  legal: [
    { name: "Cookies", href: "/cookies" },
    { name: "Privacidad", href: "/privacidad" },
    { name: "Avisos", href: "/avisos_legales" },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/drplanascosmetica",
      icon: (props) => <FacebookIcon {...props} />,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/drplanascosmetica",
      icon: (props) => <InstagramIcon {...props} />,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UCzq1xwJv5UbQreAA69t_KgQ",
      icon: (props) => <YoutubeIcon {...props} />,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/clinicaplanas",
      icon: (props) => <TwitterIcon {...props} />,
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto pt-12 pb-4 px-4 sm:px-6 lg:pt-16 lg:pb-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <div id="logo" className="flex items-center text-gray-500">
              <h1 className="text-sm pt-4" style={{ fontFamily: "Copperplate Gothic" }}>
                DR. JORGE PLANAS
              </h1>
              <DrPlanasLogo className="h-10 w-10" />
            </div>
            <div id="about-planas" className="text-gray-500 text-sm">
              <p className="font-bold">
                El Dr. Jorge Planas se encuentra entre los 100 mejores médicos de España.
              </p>
              <p className="my-5">
                La revista Forbes lo distinguió como uno de los especialistas en cirugía plástica y
                estética más prestigiosos de España.
              </p>

              <div className="ml-3 -mt-2 mb-6 mr-4">
                <QuoteIcon className=" w-8 text-gray-700 -mb-4 -ml-6" />
                <p className="text-left italic font-medium text-gray-400">
                  La misión del cirujano plástico es curar el alma
                </p>
              </div>
            </div>
            <div id="social">
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mb-3 pt-3">
                Síguenos
              </h3>
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="space-y-8 xl:col-span-2">
            <div className="mt-12 grid grid-cols-4 gap-4 xl:mt-0 xl:col-span-4">
              <div className="sm:col-start-2 col-span-2 sm:col-span-1">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-500 hover:text-gray-600"
                        children={item.name}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {/*  */}
              <div className="sm:col-start-3 col-span-2 sm:col-span-1">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Políticas legales
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-500 hover:text-gray-600"
                        children={item.name}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="sm:col-start-2 col-span-4 sm:col-span-3 mt-8 sm:mt-8">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Contacto
                </h3>
                <div className="text-base text-gray-500">
                  <p>Lunes a Viernes: 9AM - 5PM CEST</p>
                  <p className="hover:text-gray-600">pedidos@drplanascosmetica.com</p>
                  <p>Telefono: +34 688 640 533</p>
                </div>
              </div>
              <div className="sm:col-start-2 col-span-4 sm:col-span-3 mt-8 sm:mt-8">
                <h3 className="text-xs font-semibold text-gray-400 tracking-wider uppercase mb-4">
                  Metodos de Pago
                </h3>
                <div className="sm:flex sm:space-x-2 space-y-2 sm:space-y-0 justify-center sm:justify-start items-center">
                  <div className="flex space-x-2 justify-center items-center">
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/american_express-2264c9b8b57b23b0b0831827e90cd7bcda2836adc42a912ebedf545dead35b20.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/visa-319d545c6fd255c9aad5eeaad21fd6f7f7b4fdbdb1a35ce83b89cca12a187f00.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/maestro-d2055c6b416c46cf134f393e1df6e0ba31722b623870f954afd392092207889c.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/master-173035bc8124581983d4efa50cf8626e8553c2b311353fbf67485f9c1a2b88d1.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/paypal-49e4c1e03244b6d2de0d270ca0d22dd15da6e92cc7266e93eb43762df5aa355d.svg" />
                  </div>
                  <div className="flex space-x-2 justify-center items-center">
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/apple_pay-f6db0077dc7c325b436ecbdcf254239100b35b70b1663bc7523d7c424901fa09.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/google_pay-c66a29c63facf2053bf69352982c958e9675cabea4f2f7ccec08d169d1856b31.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/shopify_pay-100fde795157a3d1c18042346cf8dbd1fcf4c4f53c20064e13ea2799eb726655.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/bancontact-4ccc47bbb25524ce02ac8a0041bcce3041615434e865bfc99edb637ceef193fa.svg" />
                    <img src="https://cdn.shopify.com/shopifycloud/shopify/assets/payment_icons/ideal-35160b934b25f7635f1bf94b7fbec57a1e3e44d946e811e6aba472e11142cbcd.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-6 text-base text-gray-500 xl:text-center">
          GUSTOPHARMA CONSUMER HEALTH S.L.
        </p>
        <div className="mt-6 border-t border-gray-400 pt-8">
          <p className="text-base text-gray-500 xl:text-center">
            &copy; 2021 Dr. Planas Cosmética. Todos los derechos reservados.
          </p>
          <p className="mt-6 text-sm text-gray-600 xl:text-center">
            This website is not part of Facebook or Facebook Inc. Additionally, this site is NOT
            endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK Inc.
          </p>
        </div>
      </div>
    </footer>
  );
}
