import React from "react";

export default ({ text }: { text: string }) => {
  const BLACK_FRIDAY = 1669417199000;
  const BLACK_SATURDAY = 1669503599000;
  const BLACK_SUNDAY = 1669589999000;
  const BLACK_MONDAY = 1669676399000;

  return (
    <div className="bg-gray-900" style={{ zIndex: 2 }}>
      <div className="max-w-6xl mx-auto py-4">
        <div className="flex space-x-2 justify-center items-center text-white font-bold tracking-widest mx-4 font-[Montserrat] text-[14px] uppercase">
          {text}

          {/* Cyber Monday | Hasta el 40% de descuento
          {Date.now() <= BLACK_FRIDAY && <CountdownTimer targetDate={BLACK_SATURDAY} />}
          {BLACK_FRIDAY < Date.now() && Date.now() <= BLACK_SATURDAY && (
            <CountdownTimer targetDate={BLACK_SATURDAY} />
          )}
          {BLACK_SATURDAY < Date.now() && Date.now() <= BLACK_SUNDAY && (
            <CountdownTimer targetDate={BLACK_SUNDAY} />
          )}
          {BLACK_SUNDAY < Date.now() && Date.now() <= BLACK_MONDAY && (
            <CountdownTimer targetDate={BLACK_MONDAY} />
          )} */}
        </div>
      </div>
    </div>
  );
};

const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = React.useState(countDownDate - new Date().getTime());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  // calculate time left
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

const CountdownTimer = ({ targetDate }: { targetDate: number }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);
  if (hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <span className="ml-5 w-40 ">
        {hours}:{minutes}:{seconds}
      </span>
    );
  }
};

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>!Se Acabó!</span>
    </div>
  );
};
