import "./src/css/index.css";

import "./src/css/fonts.css";

// Fonts from fontsource packages.
import "@fontsource/source-sans-pro/200.css"; // Extra Light
import "@fontsource/source-sans-pro/300.css"; // Light
import "@fontsource/source-sans-pro/400.css"; // Regular
import "@fontsource/source-sans-pro/600.css"; // Semi-Bold
import "@fontsource/source-sans-pro/700.css"; // Bold
import "@fontsource/source-sans-pro/900.css"; // Black

// import "@fontsource/montserrat/200.css"; // Extra Light
// import "@fontsource/montserrat/300.css"; // Light
// import "@fontsource/montserrat/400.css"; // Regular
// import "@fontsource/montserrat/600.css"; // Semi-Bold
// import "@fontsource/montserrat/700.css"; // Bold
// import "@fontsource/montserrat/900.css"; // Black

import * as React from "react";
import { StoreProvider } from "./src/context/StoreContext";

export const wrapRootElement = ({ element }) => <StoreProvider>{element}</StoreProvider>;

// Require and attach the smooth-scroll library to any anchor tag containing a hashtag.
if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}
/* 
Thin            100     
Extra Light     200
Light           300
Regular         400
Medium          500
Semi-Bold       600
Bold            700
Extra-Bold      800
Black           900 
*/
