import React from "react";

// Taken from gatsbyjs/gatsby-starter-shopify/src/context/store-context.jsx
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";
import { StaticImage } from "gatsby-plugin-image";

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
);

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  isCartOpen: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: (checkoutId: string, lineItemId: string) => {},
  updateLineItem: () => {},
  buyNow: (variantId: string, quantity: number) => {},
  setIsCartOpen: (state: boolean) => {},

  client,
  checkout: {
    lineItems: [],
  },
  featureImages: {
    DPC001: <StaticImage alt="Crema " src="../static/product-cream.png" />,
    DPC002: <StaticImage alt="Sérum" src="../static/product-serum.png" />,
    DPC003: <StaticImage alt="Tónico" src="../static/product-cream-model.png" />,
    DPC004: <StaticImage alt="Limpiador" src="../static/product-cream-water.png" />,
    DPC005: <StaticImage alt="Pack" src="../static/product-pack.png" />,
  },
  productNames: {
    DPC001: "Crema Antiedad Reparadora",
    DPC002: "Serum Antiarrugas",
    DPC003: "Limpiador Pieles Mixtas",
    DPC004: "Tónico Pieles Mixtas",
    DPC005: `Pack Antiaging Infalible: \nCrema + Serum`,
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [isCartOpen, setIsCartOpen] = React.useState(false);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    return client.checkout.addLineItems(checkoutID, lineItemsToUpdate).then((res) => {
      setCheckout(res);
      setLoading(false);
      setIsCartOpen(true);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);

    return client.checkout.removeLineItems(checkoutID, [lineItemID]).then((res) => {
      setCheckout(res);
      setLoading(false);
    });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];

    return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then((res) => {
      setCheckout(res);
      setLoading(false);
    });
  };

  const buyNow = async (variantId, quantity) => {
    setLoading(true);
    const straightCheckout = await client.checkout.create();
    await client.checkout.addLineItems(straightCheckout.id, [{ variantId, quantity }]);
    window.open(straightCheckout.webUrl, "_blank");
    setLoading(false);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        buyNow,
        checkout,
        loading,
        didJustAddToCart,
        isCartOpen,
        setIsCartOpen,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
