exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-black-friday-tsx": () => import("./../../../src/pages/black-friday.tsx" /* webpackChunkName: "component---src-pages-black-friday-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-masterclass-index-tsx": () => import("./../../../src/pages/masterclass/index.tsx" /* webpackChunkName: "component---src-pages-masterclass-index-tsx" */),
  "component---src-pages-productos-products-yaml-slug-tsx": () => import("./../../../src/pages/productos/{productsYaml.slug}.tsx" /* webpackChunkName: "component---src-pages-productos-products-yaml-slug-tsx" */),
  "component---src-pages-secretos-index-tsx": () => import("./../../../src/pages/secretos/index.tsx" /* webpackChunkName: "component---src-pages-secretos-index-tsx" */),
  "component---src-pages-secretos-invitacion-tsx": () => import("./../../../src/pages/secretos/invitacion.tsx" /* webpackChunkName: "component---src-pages-secretos-invitacion-tsx" */),
  "component---src-pages-secretos-masterclass-tsx": () => import("./../../../src/pages/secretos/masterclass.tsx" /* webpackChunkName: "component---src-pages-secretos-masterclass-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-about-drplanas-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/vercel/path0/content/blog-posts/about-drplanas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-about-drplanas-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-about-linea-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/vercel/path0/content/blog-posts/about-linea/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-about-linea-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-consejos-alimentacion-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/vercel/path0/content/blog-posts/consejos-alimentacion/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-consejos-alimentacion-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-eliminar-grasa-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/vercel/path0/content/blog-posts/eliminar-grasa/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-eliminar-grasa-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-revolucion-antiaging-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/vercel/path0/content/blog-posts/revolucion-antiaging/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-revolucion-antiaging-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-secretos-belleza-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/vercel/path0/content/blog-posts/secretos-belleza/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-vercel-path-0-content-blog-posts-secretos-belleza-index-mdx" */)
}

