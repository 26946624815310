import React from "react";

import { StaticQuery, graphql } from "gatsby";
// import { Helmet } from "react-helmet";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Banner from "components/Banner";
import { WrapPagesQuery } from "../@types/graphql-types";
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";

export default () => <></>;

interface IWrapPagesProps {
  element: React.ReactElement;
  props: {
    location: {
      pathname: string;
    };
  };
}

export function wrapPagesDeep({ element, props }: IWrapPagesProps) {
  if (props.location.pathname.includes("/test")) {
    return (
      <>
        <FpjsProvider loadOptions={{ apiKey: "94W55Wz6I0TPSUhEe4aM" }}>{element}</FpjsProvider>
      </>
    );
  }
  if (props.location.pathname.includes("/secretos")) {
    return <>{element}</>;
  }
  if (props.location.pathname.includes("/masterclass")) {
    return <>{element}</>;
  }
  if (props.location.pathname.includes("/black-friday")) {
    return <>{element}</>;
  }

  return (
    <>
      {/* <Helmet>
      </Helmet> */}
      {/* <div className="bg-black" style={{ zIndex: 2 }}>
        <div className="max-w-6xl mx-auto py-4">
          <div className="flex space-x-2 justify-center items-center text-white font-black tracking-widest mx-4 font-[Montserrat] text-[20px]">
            BLACK FRIDAY VIP - Termina jueves 24 de noviembre a las 23:59
          </div>
        </div>
      </div> */}

      <StaticQuery
        query={graphql`
          query {
            allPagesYaml(filter: { page: { eq: "global" } }) {
              nodes {
                banner {
                  text1
                  text2
                }
              }
            }
          }
        `}
        render={(data: WrapPagesQuery) => (
          <Banner
            text={`${data.allPagesYaml.nodes[0].banner.text1} | ${data.allPagesYaml.nodes[0].banner.text2}`}
          />
        )}
      />

      <Navbar />
      {element}
      <Footer />
    </>
  );
}
