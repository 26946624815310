import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DrPlanasLogo } from "components/Graphics";
import { EmojiSadIcon, MenuIcon, ShoppingCartIcon, XIcon } from "@heroicons/react/outline";
import { formatPrice } from "utils/formatPrice";
import { StoreContext } from "context/StoreContext";

export default () => <StaticQuery query={query} render={Navbar} />;

const query = graphql`
  query {
    allProductsYaml(sort: { fields: order, order: ASC }) {
      edges {
        node {
          hidden
          storefrontId
          slug
          name
          available_for_sale
          featuredImageAlt
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    allNavigationYaml(sort: { fields: pages___order }) {
      edges {
        node {
          id
          pages {
            name
            href
          }
        }
      }
    }
  }
`;

function Navbar(data) {
  const { checkout, isCartOpen, setIsCartOpen } = React.useContext(StoreContext);
  let [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <header className="relative bg-gray-100 sticky top-0 border-b border-gray-200 z-40">
      <nav className="h-16 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between z-10">
        {/* Logo (lg+) */}
        <Link className="hidden lg:flex lg:flex-1 items-center text-gray-500 z-20" to="/">
          <h1 className="uppercase text-sm pt-3 font-['Copperplate_Gothic']">Dr. Jorge Planas</h1>
          <DrPlanasLogo className="h-10 w-10" />
        </Link>

        {/* Flyout menus */}
        <div className="hidden h-full lg:flex">
          <Popover.Group className="px-4 bottom-0 inset-x-0 z-20">
            <div className="h-full flex justify-center space-x-8">
              <Popover className="flex">
                {({ open }) => (
                  <>
                    <div className="relative flex">
                      <Popover.Button
                        className={classNames(
                          open
                            ? "border-gray-700 text-gray-400"
                            : "border-transparent text-gray-900 hover:text-gray-400",
                          " relative z-10 flex items-center transition-colors ease-out",
                          "duration-100 text-sm font-medium border-b-2 -mb-px pt-px"
                        )}
                      >
                        <MenuItem item="Productos" />
                      </Popover.Button>
                    </div>

                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Popover.Panel className="absolute top-full inset-x-0 text-sm text-gray-500">
                        {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                        <div
                          className="absolute inset-0 top-1/2 bg-white shadow"
                          aria-hidden="true"
                        />

                        <div className="relative bg-white">
                          <div className="max-w-7xl mx-auto px-8">
                            <div className="grid grid-cols-5 gap-y-10 gap-x-8 py-8">
                              {data.allProductsYaml.edges.map(({ node }) => {
                                if (node.hidden) return;
                                return (
                                  <div key={node.slug} className="group relative">
                                    <div
                                      className="hidden absolute group-hover:flex items-center justify-center backdrop-blur-sm bg-[#F6CA55] bg-opacity-[0.03] w-full aspect-square z-10 font-medium text-lg tracking-wider"
                                      children={node.available_for_sale ? "Comprar" : "Agotado"}
                                    />

                                    <GatsbyImage
                                      className={`aspect-square ${
                                        !node.available_for_sale &&
                                        "saturate-0 opacity-70 contrast-130 cursor-no-drop"
                                      }`}
                                      alt={node.featuredImageAlt}
                                      image={getImage(node.featuredImage)!}
                                      key={node.slug}
                                    />

                                    {node.available_for_sale ? (
                                      <>
                                        <Popover.Button
                                          as={Link}
                                          to={`/productos/${node.slug}`}
                                          className="mt-4 block font-medium text-gray-900"
                                        >
                                          <span
                                            // this span make the image work as a Link aswell
                                            className="absolute z-10 inset-0"
                                            aria-hidden
                                          />
                                          {node.name}
                                        </Popover.Button>
                                      </>
                                    ) : (
                                      <div
                                        className="mt-4 block font-medium text-gray-900 cursor-no-drop opacity-50"
                                        children={node.name}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {data.allNavigationYaml.edges[0].node.pages.map((page) => (
                <a
                  key={page.name}
                  href={page.href}
                  className="flex items-center text-sm font-medium text-gray-900 hover:text-gray-400"
                >
                  <MenuItem item={page.name} />
                </a>
              ))}

              <button className="group relative my-3.5 px-3 flex items-center text-sm font-medium bg-[#1d4aff] bg-opacity-80 hover:bg-opacity-100 hover:shadow-lg hover:shadow-[#1d4aff]/40  transition-all text-white z-10 overflow-hidden cursor-default">
                <p className="translate-y-0 group-hover:-translate-y-7 transition-all">
                  Dr Planas Wellness Club
                </p>
                <p className="absolute left-0 right-0 translate-y-7 group-hover:translate-y-0 z-0 transition-all">
                  Muy Pronto
                </p>
              </button>
            </div>
          </Popover.Group>
        </div>

        {/* Mobile menu (lg-) */}
        <div className="flex-1 flex items-center lg:hidden">
          <button
            type="button"
            className="-ml-2 bg-white p-2 rounded-md text-gray-400"
            onClick={() => setIsMenuOpen(true)}
          >
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <MobileNavbar
          pages={data.allNavigationYaml.edges[0].node.pages}
          products={data.allProductsYaml.edges}
          isNavOpen={isMenuOpen}
          setIsNavOpen={setIsMenuOpen}
        />

        {/* Logo (lg-) */}
        <Link to="/">
          <div id="logo" className="flex items-center text-gray-500 lg:hidden">
            <span className="sr-only">Doctor Jorge Planas</span>
            <h1 className="text-sm pt-4" style={{ fontFamily: "Copperplate Gothic" }}>
              DR. JORGE PLANAS
            </h1>
            <DrPlanasLogo className="h-10 w-10" />
          </div>
        </Link>
        {/* Cart */}
        <div className="flex flex-1 items-center justify-end">
          <Popover className="ml-4 flow-root text-sm lg:relative lg:ml-8 z-20">
            {({ open, close }) => {
              React.useEffect(() => {
                setIsCartOpen(open);
              }, [open]);
              return (
                <>
                  <Popover.Button className="group -m-2 p-2 flex items-center">
                    <ShoppingCartIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                      {checkout.lineItems.reduce((a, b) => a + b.quantity, 0)}
                    </span>
                    <span className="sr-only">items in cart, view bag</span>
                  </Popover.Button>

                  {isCartOpen && (
                    <Transition
                      show={isCartOpen}
                      as={React.Fragment}
                      enter="transition ease-out duration-200"
                      // enterFrom="opacity-0"
                      // enterTo="opacity-100"
                      leave="transition ease-in duration-150"
                      // leaveFrom="opacity-100"
                      // leaveTo="opacity-0"
                    >
                      <Popover.Panel static>
                        <Cart closeFunction={close} />
                      </Popover.Panel>
                    </Transition>
                  )}
                </>
              );
            }}
          </Popover>
        </div>
      </nav>
    </header>
  );
}

const Cart = ({ closeFunction }) => {
  const { checkout, featureImages, productNames, setIsCartOpen, removeLineItem } =
    React.useContext(StoreContext);

  return (
    <div className="absolute top-16 inset-x-0 mt-px backdrop-blur-xl bg-white shadow-lg border border-t-0 sm:p-3 lg:top-full lg:left-auto lg:right-0 lg:mt-5 lg:-mr-1.5 lg:w-96 lg:rounded-b lg:ring-1 lg:ring-black lg:ring-opacity-5">
      <h2 className="sr-only">Cesta de Compra</h2>
      <div className="w-full text-right">
        <button
          className="bg-gray-200 p-1 rounded-full"
          onClick={() => {
            setIsCartOpen(false);
            closeFunction(); // so that cart button receives focus after closing
          }}
        >
          <XIcon className="w-4 h-4" />
        </button>
      </div>

      {checkout?.lineItems?.length == 0 ? (
        <div className="w-full py-40 px-4 text-sm font-medium text-center">
          <EmojiSadIcon className="h-20 w-20 mx-auto mb-6" />
          Tu cesta está vacía
        </div>
      ) : (
        <>
          <ul className="divide-y divide-gray-200">
            {checkout?.lineItems?.map((item) => (
              <li key={item.id} className="py-4 flex items-start space-x-4">
                {/* Image + quantity */}
                <div className="relative flex">
                  <div className="flex-none h-16 w-16 border border-gray-300">
                    {featureImages[item.variant.sku]}
                  </div>

                  <div className="absolute right-0 top-0 -mt-2 -mr-2 bg-red-700 h-5 w-5 rounded-full flex items-center justify-center">
                    <p className="text-xs font-bold text-white">{item.quantity}</p>
                  </div>
                </div>

                {/* Title + remove button + price */}
                <div className="flex justify-between w-full">
                  <div>
                    <h3 className="font-medium text-gray-900">
                      {productNames[item.variant.sku].split("\n").map((str) => (
                        // Could've just done {item.title} but we want more control.
                        // Get product names locally and display with new line
                        <p>{str}</p>
                      ))}
                    </h3>
                    <button
                      className="text-xs font-medium opacity-40 hover:opacity-60 underline transition-opacity"
                      children="Eliminar"
                      onClick={() => removeLineItem(checkout.id, item.id)}
                    />
                  </div>

                  <p>
                    {formatPrice(
                      item.variant.priceV2.currencyCode,
                      Number(item.variant.priceV2.amount)
                    )}
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex justify-between w-full border-t-2 font-medium pt-2 mt-2">
            <p>Subtotal</p>
            <p>
              {formatPrice(
                checkout?.subtotalPriceV2?.currencyCode,
                Number(checkout?.subtotalPriceV2?.amount)
              )}
            </p>
          </div>
          <div className="flex flex-col space-y-1 mt-5">
            <a href={checkout.webUrl}>
              <button
                className="w-full bg-[#1d4aff] shadow-sm py-3 px-4 text-sm font-medium font-[Montserrat] hover:brightness-[1.75] text-white"
                children="Realizar pedido"
              />
            </a>

            <Link to="/cart">
              <button
                className="w-full py-3 px-4 text-sm underline font-medium font-[Montserrat] hover:bg-black/5 text-[#1d4aff]"
                children="Ver cesta"
                onClick={() => {
                  setIsCartOpen(false);
                  closeFunction(); // so that cart button receives focus after closing
                }}
              />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

const MenuItem = ({ item }: { item: string }) => (
  <div className="px-3 py-2 hover:bg-gray-100 rounded-md">{item}</div>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MobileNavbar({ pages, products, isNavOpen, setIsNavOpen }) {
  return (
    <div className="bg-white">
      <Transition.Root show={isNavOpen} as={React.Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setIsNavOpen}>
          <Transition.Child
            as={React.Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setIsNavOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex px-4 space-x-8">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "text-planasBlue border-planasBlue"
                            : "text-planasBlueDark border-transparent",
                          "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                        )
                      }
                    >
                      Productos
                    </Tab>
                  </Tab.List>
                </div>
                <Tab.Panels as={React.Fragment}>
                  <Tab.Panel className="px-4 py-6 space-y-12">
                    <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                      {products.map(({ node }) => {
                        if (node.hidden) return;
                        return (
                          <div key={node.slug} className="group relative">
                            <div className="aspect-w-1 aspect-h-1 rounded-md bg-gray-100 overflow-hidden group-hover:opacity-75] relative">
                              <GatsbyImage
                                className={`aspect-square ${
                                  !node.available_for_sale &&
                                  "saturate-0 opacity-70 contrast-130 cursor-no-drop"
                                }`}
                                alt={node.featuredImageAlt}
                                image={getImage(node.featuredImage)!}
                                key={node.slug}
                              />
                              {!node.available_for_sale && (
                                <p
                                  className="absolute z-20 top-1/2 w-full text-center text-sm font-medium"
                                  children="AGOTADO"
                                />
                              )}
                            </div>

                            {node.available_for_sale ? (
                              <Link
                                to={`/productos/${node.slug}`}
                                className="mt-4 block font-medium text-gray-900"
                                onClick={() => setIsNavOpen(false)}
                              >
                                <span
                                  // this span make the image work as a Link aswell
                                  className="absolute z-10 inset-0"
                                  aria-hidden
                                />
                                {node.name}
                              </Link>
                            ) : (
                              <div
                                className="mt-4 block font-medium text-gray-900 cursor-no-drop opacity-50"
                                children={node.name}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>

              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                {pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <a href={page.href} className="-m-2 p-2 block font-medium text-gray-900">
                      {page.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
